export const MASK_SQUIRCLE =
  'https://images.prismic.io/virginexperiencedays/b681f218-3fac-4534-be88-633980d60bb7_squircle.png';

export const GIFT_CARD_SLIDER = {
  tray: 'https://images.prismic.io/virginexperiencedays-dev/9eaaaa1e-d855-4bba-9448-4c1570c03f8c_gc-card.png',
  wallet:
    'https://images.prismic.io/virginexperiencedays-dev/5e1b960a-882d-475a-8adc-9c7883ce9bf9_gc-wallet.png',
};

export const CORPORATE_LEAD_FORM_IMAGE = {
  desktop:
    'https://images.prismic.io/virginexperiencedays/182709b0-61ee-4b71-86e9-53f103ee48fe_corporate-lead-form-desktop.png',
  mobile:
    'https://images.prismic.io/virginexperiencedays/77f330ef-cfd3-4c89-85fd-8771b69e7c85_corporate-lead-form-mobile.png',
};

export const PROMO_BANNER_MASK_IMAGE_MOBILE =
  'https://images.prismic.io/virginexperiencedays/755c677e-30f9-4386-a11f-474f06433325_promo-banner-mobile-mask.png';
