import { cn } from '@virginexperiencedays/tailwind';

export type ColumnType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type ColumnsType = {
  mobile?: ColumnType;
  largeMobile?: ColumnType;
  tablet?: ColumnType;
  desktop?: ColumnType;
  wide?: ColumnType;
};

export const columnsConfig = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
};

const responsiveColumnsConfig = {
  mobile: columnsConfig,
  largeMobile: {
    1: 'sm:grid-cols-1',
    2: 'sm:grid-cols-2',
    3: 'sm:grid-cols-3',
    4: 'sm:grid-cols-4',
    5: 'sm:grid-cols-5',
    6: 'sm:grid-cols-6',
    7: 'sm:grid-cols-7',
    8: 'sm:grid-cols-8',
  },
  tablet: {
    1: 'md:grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3',
    4: 'md:grid-cols-4',
    5: 'md:grid-cols-5',
    6: 'md:grid-cols-6',
    7: 'md:grid-cols-7',
    8: 'md:grid-cols-8',
  },
  desktop: {
    1: 'lg:grid-cols-1',
    2: 'lg:grid-cols-2',
    3: 'lg:grid-cols-3',
    4: 'lg:grid-cols-4',
    5: 'lg:grid-cols-5',
    6: 'lg:grid-cols-6',
    7: 'lg:grid-cols-7',
    8: 'lg:grid-cols-8',
  },
  wide: {
    1: 'xl:grid-cols-1',
    2: 'xl:grid-cols-2',
    3: 'xl:grid-cols-3',
    4: 'xl:grid-cols-4',
    5: 'xl:grid-cols-5',
    6: 'xl:grid-cols-6',
    7: 'xl:grid-cols-7',
    8: 'xl:grid-cols-8',
  },
};
/*
 * Mobile first approach, so if ever needed to overwrite a column on a larger screen up just define it explicitly
 */
export const mapColumns = (columns: ColumnsType | ColumnType, mobileCarousel: boolean): string => {
  if (typeof columns === 'number') {
    return columnsConfig[columns];
  }

  const { mobile = 1, largeMobile, tablet, desktop, wide } = columns;

  const mobileStyle = responsiveColumnsConfig.mobile[mobile];
  const largeMobileStyle = largeMobile ? responsiveColumnsConfig.largeMobile[largeMobile] : null;
  const tabletStyle = tablet ? responsiveColumnsConfig.tablet[tablet] : null;
  const desktopStyle = desktop ? responsiveColumnsConfig.desktop[desktop] : null;
  const wideStyle = wide ? responsiveColumnsConfig.wide[wide] : null;

  return cn(
    !mobileCarousel && mobileStyle,
    !mobileCarousel && largeMobileStyle,
    !mobileCarousel && tabletStyle,
    // starting breakpoint when mobileCarousel is true and size config object passed is just mobile
    desktopStyle
      ? desktopStyle
      : responsiveColumnsConfig.desktop?.[tablet]
      ? responsiveColumnsConfig.desktop?.[tablet]
      : responsiveColumnsConfig.desktop?.[largeMobile]
      ? responsiveColumnsConfig.desktop?.[largeMobile]
      : responsiveColumnsConfig.desktop?.[mobile],
    wideStyle
  );
};
