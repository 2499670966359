import type { Override } from '@virginexperiencedays/categories/src/types';
import { categoryTypes } from '@virginexperiencedays/search/constants';

/*
 * This function will should be used to calculate next level of categoryType if currentCategoryType is equal to categoryType, assuming that attribute is an array (0 based index) and we only support 3lvls of hierarchies now (0,1,2)
 * Will return next level based on the current Lvl if category Type is matching currentCategoryType
 */
export const getAttributeLvl = (
  currentCategoryType: categoryTypes,
  categoryType: categoryTypes,
  currentLvl: number,
  overridesSlugInfo?: Override['slugInfo']
) => {
  const nextLvl = currentLvl + 1;

  if (overridesSlugInfo) {
    //  do not use current category type as seo overrides is either CATEGORY or OCCASION whilst we want to get the next level of the categoryType that is existing in overridesSlugInfo
    //  example:
    //  driving/london -> hierarchical next level for : category, location, rest should be 0
    const currentOverrideLvl = getOverrideLvl(categoryType, overridesSlugInfo);
    // its necessary to do null comparison here, apparently 0 is falsey too
    const nextOverrideLvl = currentOverrideLvl !== null ? currentOverrideLvl + 1 : 0;

    return Math.min(nextOverrideLvl, 2);
  }

  return currentCategoryType === categoryType ? Math.min(nextLvl, 2) : 0;
};

export const getCurrentOverrideLvl = (
  categoryType: categoryTypes,
  overridesSlugInfo: Override['slugInfo']
) => {
  const currentOverrideLvl = getOverrideLvl(categoryType, overridesSlugInfo);
  return currentOverrideLvl !== null ? currentOverrideLvl : 0;
};

// this should be used to calculate current level when overridesSlugInfo is given
export function getOverrideLvl(
  categoryType: categoryTypes,
  overrideSlug: Override['slugInfo']
): number | null {
  // 0 indexed
  const activeSlug = overrideSlug.find((slug) => slug.type === categoryType);

  if (!activeSlug) return null;

  return activeSlug.hierarchy.length - 1;
}
