import { HTMLAttributes, ReactNode } from 'react';

import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@virginexperiencedays/tailwind';

const visualHeadingVariants = cva('m-0 font-serif font-normal tracking-tight', {
  variants: {
    size: {
      '1': 'text-5xl sm:text-8xl',
      '2': 'text-4xl sm:text-6xl',
      '3': 'text-[2rem] sm:text-5xl',
      '4': 'text-3xl sm:text-4xl',
      '5': 'text-2xl',
      '6': 'text-xl',
    },
    leading: {
      none: 'leading-none',
      base: 'leading-base',
      snug: 'leading-tight',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
    },
  },
  defaultVariants: {
    size: '2',
    leading: 'snug',
  },
});

export type VisualHeadingProps = {
  size?: VariantProps<typeof visualHeadingVariants>['size'];
  leading?: VariantProps<typeof visualHeadingVariants>['leading'];
  children: ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & HTMLAttributes<HTMLHeadingElement>;

export const VisualHeading = ({
  className,
  as,
  children,
  size = '1',
  leading = 'snug',
  ...rest
}: VisualHeadingProps) => {
  const Tag = as ?? `h${size}`;

  return (
    <Tag
      data-testid="visual-heading"
      className={cn(visualHeadingVariants({ size, leading }), className)}
      {...rest}
    >
      {children}
    </Tag>
  );
};
