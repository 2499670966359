export const PRODUCT_DAYS_NEW = 30;

export const NON_SAPLING_PRODUCT_SLUG_MAP = new Map([
  ['amber-collection', { id: '63', slug: 'amber-collection', title: 'The Amber Collection' }],
  [
    'emerald-collection',
    { id: '1357', slug: 'emerald-collection', title: 'The Emerald Collection' },
  ],
  ['chrome-collection', { id: '1358', slug: 'chrome-collection', title: 'The Chrome Collection' }],
  [
    'platinum-collection',
    { id: '1450', slug: 'platinum-collection', title: 'The Platinum Collection' },
  ],
  ['indigo-collection', { id: '1497', slug: 'indigo-collection', title: 'The Indigo Collection' }],
  [
    'scarlet-collection',
    { id: '1498', slug: 'scarlet-collection', title: 'The Scarlet Collection' },
  ],
  ['ebony-collection', { id: '1499', slug: 'ebony-collection', title: 'The Ebony Collection' }],
  [
    'magenta-collection',
    { id: '1500', slug: 'magenta-collection', title: 'The Magenta Collection' },
  ],
  [
    'deluxe-collection-for-her',
    { id: '1501', slug: 'deluxe-collection-for-her', title: 'Deluxe Collection for Her' },
  ],
  [
    'deluxe-collection-for-him-1502',
    { id: '1502', slug: 'deluxe-collection-for-him-1502', title: 'Deluxe Collection for Him' },
  ],
  [
    'enjoy-london-collection-for-two',
    {
      id: '1503',
      slug: 'enjoy-london-collection-for-two',
      title: 'Enjoy London Collection for Two',
    },
  ],
  ['couples-collection', { id: '1518', slug: 'couples-collection', title: 'Couples Collection' }],
  [
    'graphite-collection',
    { id: '1536', slug: 'graphite-collection', title: 'The Graphite Collection' },
  ],
  ['azure-collection', { id: '1601', slug: 'azure-collection', title: 'The Azure Collection' }],
  [
    'the-gold-collection',
    { id: '1650', slug: 'the-gold-collection', title: 'The Gold Collection' },
  ],
  ['drivers-collection', { id: '1933', slug: 'drivers-collection', title: 'Drivers Collection' }],
  [
    'sport-tour-collection',
    { id: '1935', slug: 'sport-tour-collection', title: 'Sporting Venue Tour Collection' },
  ],
  [
    'classic-collection-for-her',
    { id: '2539', slug: 'classic-collection-for-her', title: 'Classic Collection for Her' },
  ],
  [
    'classic-collection-for-him',
    { id: '2540', slug: 'classic-collection-for-him', title: 'Classic Collection for Him' },
  ],
  [
    'spa-break-for-two-collection',
    { id: '3283', slug: 'spa-break-for-two-collection', title: 'Spa Break for Two Collection' },
  ],
  ['spa-day-collection', { id: '3386', slug: 'spa-day-collection', title: 'Spa Day Collection' }],
  [
    'luxury-spa-day-collection',
    { id: '3388', slug: 'luxury-spa-day-collection', title: 'Luxury Spa Day Collection' },
  ],
  [
    'castles-palaces-and-gardens-with-afternoon-tea-for-two',
    {
      id: '3734',
      slug: 'castles-palaces-and-gardens-with-afternoon-tea-for-two',
      title: 'Castles, Palaces and Gardens with Afternoon Tea for Two',
    },
  ],
  [
    'champagne-afternoon-tea-for-two',
    {
      id: '3771',
      slug: 'champagne-afternoon-tea-for-two',
      title: 'Champagne Afternoon Tea for Two',
    },
  ],
  [
    'beer-lovers-collection',
    { id: '4161', slug: 'beer-lovers-collection', title: 'Beer Lovers Collection' },
  ],
  [
    'one-night-stay-collection',
    { id: '5480', slug: 'one-night-stay-collection', title: 'One Night Stay Collection' },
  ],
  [
    'indulgent-spa-day-collection',
    { id: '5496', slug: 'indulgent-spa-day-collection', title: 'Indulgent Spa Day Collection' },
  ],
  [
    'one-night-leisure-break-collection',
    {
      id: '5529',
      slug: 'one-night-leisure-break-collection',
      title: 'One Night Leisure Break Collection',
    },
  ],
  [
    'one-night-glamping-break-collection',
    {
      id: '5620',
      slug: 'one-night-glamping-break-collection',
      title: 'One Night Glamping Break Collection',
    },
  ],
  [
    'discover-london-collection',
    { id: '5757', slug: 'discover-london-collection', title: 'The Discover London Collection' },
  ],
  [
    'experience-london-collection',
    { id: '5759', slug: 'experience-london-collection', title: 'The Experience London Collection' },
  ],
  [
    'love-london-collection',
    { id: '5760', slug: 'love-london-collection', title: 'The Love London Collection' },
  ],
  [
    'one-night-scottish-break-collection',
    {
      id: '5823',
      slug: 'one-night-scottish-break-collection',
      title: 'One Night Scottish Break Collection',
    },
  ],
  [
    'luxury-afternoon-tea-collection',
    {
      id: '6910',
      slug: 'luxury-afternoon-tea-collection',
      title: 'Luxury Afternoon Tea Collection',
    },
  ],
  [
    'london-dining-collection',
    { id: '6913', slug: 'london-dining-collection', title: 'The London Dining Collection' },
  ],
  [
    'one-night-city-escape-for-2',
    {
      id: '6924',
      slug: 'one-night-city-escape-for-2',
      title: 'One Night City Hotel Escape for Two',
    },
  ],
  [
    'one-night-coastal-escape-for-two-mc',
    {
      id: '7555',
      slug: 'one-night-coastal-escape-for-two-mc',
      title: 'One Night Coastal Escape for Two',
    },
  ],
  [
    'one-night-gourmet-escape-for-two',
    {
      id: '7556',
      slug: 'one-night-gourmet-escape-for-two',
      title: 'One Night Gourmet Hotel Escape for Two',
    },
  ],
  [
    'one-night-boutique-hotel-escape-for-two',
    {
      id: '7571',
      slug: 'one-night-boutique-hotel-escape-for-two',
      title: 'One Night Boutique Hotel Escape for Two',
    },
  ],
  [
    'one-night-luxury-escape-for-two',
    {
      id: '7616',
      slug: 'one-night-luxury-escape-for-two',
      title: 'One Night Luxury Hotel Escape for Two',
    },
  ],
  [
    'one-night-country-escape-for-two-jd',
    {
      id: '7637',
      slug: 'one-night-country-escape-for-two-jd',
      title: 'One Night Country Hotel Escape for Two',
    },
  ],
  [
    'two-night-boutique-hotel-escape-for-two',
    {
      id: '9425',
      slug: 'two-night-boutique-hotel-escape-for-two',
      title: 'Two Night Boutique Hotel Escape for Two',
    },
  ],
  [
    'one-night-4-london-break-for-two',
    {
      id: '111451',
      slug: 'one-night-4-london-break-for-two',
      title: 'One Night 4* London City Break for Two',
    },
  ],
  [
    'creative-collection',
    { id: '112355', slug: 'creative-collection', title: 'Creative Collection' },
  ],
  [
    'experiences-to-enjoy-at-home-collection',
    {
      id: '112810',
      slug: 'experiences-to-enjoy-at-home-collection',
      title: 'Experiences to Enjoy At Home Collection',
    },
  ],
]);
