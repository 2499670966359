/**

- This util finds the deepest child in hierarchical menu's recursively
- We're returing currently refined item instead of its data as sometimes refined item may not contain it
*/
export function findDeepest<
  T extends {
    isRefined: boolean;
    data: T[];
  }
>(arr: T[]): T | undefined {
  const current = arr?.find((item) => item.isRefined);
  const next = current?.data?.find(({ isRefined }) => isRefined);

  if (!next) {
    return current;
  }

  return findDeepest(current.data);
}
