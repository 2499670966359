import { ReactNode } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { mapGap, mapGapY, type Space, type ResponsiveSpace } from '../../utils/mapSpace';
import { type ColumnsType, mapColumns, type ColumnType } from '../../utils/mapColumns';

export type ElementWidthType =
  | number
  | {
      mobile?: number;
      largeMobile?: number;
      tablet?: number;
      desktop?: number;
      wide?: number;
    };

export type GridProps = {
  className?: string;
  children: ReactNode;
  columns?: ColumnType | ColumnsType;
  /*
   * none: 0px;
   * xxs: 2px;
   * xs: 4px;
   * sm: 8px;
   * md: 16px;
   * lg: 24px;
   * xl: 40px;
   * 2xl: 80px;
   */
  space: Space | ResponsiveSpace;
  /*
   * none: 0px;
   * xxs: 2px;
   * xs: 4px;
   * sm: 8px;
   * md: 16px;
   * lg: 24px;
   * xl: 40px;
   * 2xl: 80px;
   */
  spaceY?: Space | ResponsiveSpace;
  dataTestId?: string;
  elementTagAs?: 'ul';
  carouselCardSize?: 'small' | 'large';
};

/**
 *
 * @param {ReactNode} children - Elements to display within the grid
 * @param {ColumnType | ColumnsType} columns - Number or object with responsive values for the number of columns to display
 * @param {Space | ResponsiveSpace } space - One of the  space breakpoints values :'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' or object with responsive values where one of space breakpoints values for the space between elements
 * @param { 'small' | 'large'} carouselCardSize - set card size if carousel on mobile is true
 */
export const Grid = ({
  className,
  children,
  columns = {
    mobile: 1,
    largeMobile: 2,
    tablet: 2,
    desktop: 3,
    wide: 4,
  },
  space,
  spaceY,
  dataTestId = 'container-grid',
  elementTagAs,
  carouselCardSize,
}: GridProps) => {
  const Tag = elementTagAs || 'div';
  return (
    <Tag
      className={cn(
        'grid grid-flow-dense',
        mapColumns(columns, !!carouselCardSize), // it will always return atleast mobile 1
        mapGap(space), // will always return atleast gap-2 (8px)
        carouselCardSize &&
          'hide-scrollbar -mx-4 grid-flow-col overflow-x-auto px-4 pb-2 md:-mx-10 md:px-10 lg:auto-cols-auto lg:grid-flow-row lg:overflow-x-visible',
        carouselCardSize === 'small' &&
          'auto-cols-[minmax(calc(80vw_-_80px),1fr)] md:auto-cols-[minmax(calc(100vw_/_3),1fr)] lg:auto-cols-[minmax(calc(100vw_/_3),1fr)]',
        carouselCardSize === 'large' &&
          'auto-cols-[minmax(calc(100vw_-_80px),1fr)] md:auto-cols-[minmax(calc(50vw_-_80px),1fr)] lg:auto-cols-[minmax(calc(50vw_-_80px),1fr)]',
        spaceY && mapGapY(spaceY),
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </Tag>
  );
};
