import { categoryTypes } from '@virginexperiencedays/search/constants';
import { createContext, type ReactNode, useContext } from 'react';

type CategoryPageContextType = {
  // This is the original url that is used behind seo override url (includes query params)
  seoOverridesUrl: string;
  categoryType: categoryTypes;
};

const CategoryPageContext = createContext<CategoryPageContextType>({
  seoOverridesUrl: null,
  categoryType: null,
});

type CategoryPageProviderProps = {
  children: ReactNode;
  initialState: CategoryPageContextType;
};

const CategoryPageContextProvider = ({ children, initialState }: CategoryPageProviderProps) => {
  return (
    <CategoryPageContext.Provider value={initialState}>{children}</CategoryPageContext.Provider>
  );
};

const useCategoryPageContext = () => {
  const context = useContext(CategoryPageContext);
  if (context === undefined) {
    throw new Error('useCategoryPageContext must be used within CategoryPageContextProvider');
  }
  return context;
};

export { CategoryPageContextProvider, useCategoryPageContext };
