import { categoryPrefixes } from '@virginexperiencedays/search/constants';
import { useRouter } from 'next/compat/router';
import { useEffect, useState } from 'react';

/*
 * This hook should be used to detect whether the given component/widget is rendered under new/old url structure for category pages
 * 1.default is false so by default we use old urls.
 * 2.default would be for SSR (initial load)
 * 3.default will be used for SEO (old urls)
 *
 * Examples of old url:
 *
 * /driving
 * /muscle-cars
 * /birthday-gifts
 * /afternoon-tea
 *
 * Examples of new urls:
 *
 * /c/driving
 * /c/driving/muscle-cars
 * /o/birthday-gifts
 * /c/afternoon-tea
 */
export const useDetectNewURL = () => {
  const router = useRouter();
  //@TODO: revisit when legacy urls gets suspended
  const [isNewURL, setIsNewURL] = useState(false);

  useEffect(() => {
    if (router?.isReady) {
      const parts = router?.asPath.split('/');
      if (Object.values(categoryPrefixes).some((prefix) => parts.includes(prefix))) {
        setIsNewURL(true);
      }
    }
  }, [router?.isReady]);

  return {
    isNewURL,
  };
};
