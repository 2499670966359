import { ReactNode, useCallback, useEffect, useState, HTMLAttributes } from 'react';
import debounce from 'lodash.debounce';
import Collapsible from 'react-collapsible';

import { useId } from '@virginexperiencedays/hooks/src/useId';

import { IconMinus } from '../../icons/IconMinus';
import { IconPlus } from '../../icons/IconPlus';
import { DisplayHeading } from '../../typography/DisplayHeading';

import { Divider } from '../Divider';

export type AccordionProps = {
  /**
   * Defines the heading of the accordion
   */
  heading: ReactNode | string;
  /**
   * Children to be rendered inside the accordion
   */
  className?: string;
  dataTestId?: string;
  /**
   * Defines if accordion has to be open by default
   */
  open?: boolean;
  children: ReactNode;
  elementTagAs?: 'li';
} & HTMLAttributes<HTMLDivElement | HTMLLIElement>;

export const Accordion = ({
  className,
  heading,
  children,
  elementTagAs,
  dataTestId = 'accordion',
  open = false,
  ...rest
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const collapsibleId = useId();
  const handleOnClickCollapsible = useCallback(
    debounce(() => {
      setIsOpen((prevState) => !prevState);
    }, 200),
    []
  );

  const Tag = elementTagAs || 'div';

  useEffect(() => {
    return () => {
      handleOnClickCollapsible.cancel();
    };
  }, [handleOnClickCollapsible]);

  return (
    <Tag className={className} data-testid={dataTestId} {...rest}>
      <div
        className="text-neutral-strong flex w-full cursor-pointer items-center justify-between gap-2 bg-transparent"
        onClick={handleOnClickCollapsible}
      >
        {typeof heading === 'string' ? (
          <DisplayHeading
            className="text-neutral-strong my-4 text-base leading-normal"
            as="h3"
            size="4"
            data-testid="accordion-heading"
          >
            {heading}
          </DisplayHeading>
        ) : (
          heading
        )}

        <div className="text-neutral-strong">
          {!isOpen ? (
            <IconPlus data-testid="accordion-icon-closed" />
          ) : (
            <IconMinus data-testid="accordion-icon-open" />
          )}
        </div>
      </div>
      <Collapsible
        trigger={null}
        triggerStyle={{
          position: 'relative',
        }}
        contentElementId={`collapsible-content-${collapsibleId}`}
        triggerElementProps={{ id: `collapsible-trigger-${collapsibleId}` }}
        open={isOpen}
        transitionTime={200}
        easing="cubic-bezier(0.34, 0.27, 0.19, 0.84)"
      >
        <div
          className="text-neutral mb-6 text-base leading-relaxed opacity-100 transition-opacity"
          data-testid={`${dataTestId}-body`}
        >
          {children}
        </div>
      </Collapsible>
      <Divider className="text-border-neutral m-0" />
    </Tag>
  );
};
